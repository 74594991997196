<template>
  <v-container fluid>
    <v-navigation-drawer
      v-model="showPSDHistory"
      app
      right
      temporary
      width="50%"
    >
      <PartSupplierDetailHistory
        :records="psdHistory"
        @close="showPSDHistory = false"
      />
    </v-navigation-drawer>
    <v-row>
      <v-col>
        <v-toolbar flat color="transparent" dense>
          <v-btn icon @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ isEditMode ? $t("parts.upsert.titleEdit") + ':' : $t("parts.upsert.titleCreate") }}

            <clipboard-copy v-if="isEditMode">
              {{ part.partNumber }}
            </clipboard-copy>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn color="error" disabled text class="mr-3" @click="deletePart">
            {{ $t("parts.upsert.submitDelete") }}
          </v-btn>

          <v-btn v-if="isEditMode" :loading="saving" :disabled="(!hasChanged || !valid) && !simulationHasDifferentPrice" color="primary" @click="save()">
            {{ $t("parts.upsert.submitEdit") }}
          </v-btn>

          <v-btn v-else :loading="creating" :disabled="!valid" color="primary" @click="create">
            {{ $t('parts.upsert.submitCreate') }}
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xs="8" sm="9">
        <v-card :loading="loading">
          <v-toolbar flat>
            <v-toolbar-title>
              Stamdata
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <div class="d-flex align-center text-caption secondary--text" style="gap: 1rem;">
              <div>
                Synkroniseret med ERP:
                <b v-if="!!part.savedToERPOn">{{ part.savedToERPOn | formatDateTime }}</b>
                <b v-else>Aldrig</b>
              </div>
              <div v-if="isEditMode">
                Oprettet: <b>{{ part.createdOn | formatDateTime }}</b>
              </div>
            </div>
          </v-toolbar>

          <v-card-text>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="part.title"
                    :label="$t('parts.upsert.title')"
                    :rules="[v => !!v || 'Skal udfyldes']"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="part.groupId"
                    :items="model.metadata.groups"
                    item-text="text"
                    item-value="value"
                    :label="$t('parts.upsert.group')"
                    :rules="[v => !!v || 'Skal udfyldes']"
                    auto-select-first
                    clearable
                    @input="simulate"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    v-model="part.categoryId"
                    :items="filteredCategories"
                    item-text="text"
                    item-value="value"
                    :label="$t('parts.upsert.category')"
                    :rules="[v => !!v || 'Skal udfyldes']"
                    auto-select-first
                    @input="assignCustomerPartCategory"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="part.detail"
                    type="text"
                    :label="$t('parts.upsert.detail')"
                    :placeholder="$t('parts.upsert.detailPlaceholder')"
                  />
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="part.description"
                    rows="1"
                    :label="$t('parts.upsert.description')"
                    :rules="[v => (!v || v.length <= 4000) || 'Maks. 4000 tegn']"
                    auto-grow
                    clearable
                    counter="4000"
                    hint="Synlig for kunden"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="part.internalNote"
                    rows="1"
                    :label="$t('parts.upsert.internalNote')"
                    :rules="[v => (!v || v.length <= 4000) || 'Maks. 4000 tegn']"
                    auto-grow
                    clearable
                    counter="4000"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="part.ean"
                    type="text"
                    :label="$t('parts.upsert.ean')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="part.manufacturerId"
                    :items="model.metadata.manufacturers"
                    item-text="text"
                    item-value="value"
                    :label="$t('parts.upsert.manufacturer')"
                    :rules="[v => !!v || 'Skal udfyldes']"
                    auto-select-first
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="part.manufacturerTypeReference"
                    type="text"
                    :label="$t('parts.upsert.manufacturerPartNumber')"
                    hint="Synlig for kunden"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="part.partUnitId"
                    :items="model.metadata.partUnits"
                    item-text="text"
                    item-value="value"
                    :label="$t('parts.upsert.partUnit')"
                    :rules="[v => !!v || 'Skal udfyldes']"
                    auto-select-first
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="part.preferredSupplierId"
                    :items="model.metadata.suppliers"
                    clearable
                    :label="$t('parts.upsert.preferredSupplier')"
                    auto-select-first
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="part.status"
                    :items="model.metadata.statuses"
                  />
                </v-col>
              </v-row>

              <v-row v-if="part.old_MainCategory || part.old_SecondaryCategory || part.old_TertiaryCategory">
                <v-col cols="12">
                  Gamle kategorier
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="part.old_MainCategory"
                    disabled
                    label="Kategori"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="part.old_SecondaryCategory"
                    disabled
                    label="Underkategori"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="part.old_TertiaryCategory"
                    disabled
                    label="Subkategori"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              Dimensioner
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-form>
              <v-text-field
                v-model.number="part.length"
                type="number"
                suffix="cm"
                :label="$t('parts.editCard.length')"
              />
              <v-text-field
                v-model.number="part.width"
                type="number"
                suffix="cm"
                :label="$t('parts.editCard.width')"
              />
              <v-text-field
                v-model.number="part.height"
                type="number"
                suffix="cm"
                :label="$t('parts.editCard.height')"
              />
              <v-text-field
                v-model.number="part.weight"
                type="number"
                suffix="gram"
                :label="$t('parts.editCard.weight')"
              />
              <v-text-field
                v-model.number="part.minimumOrderQuantity"
                type="number"
                :label="$t('parts.upsert.minimumOrderQuantity')"
              />
              <v-text-field
                v-model.number="part.packageSize"
                type="number"
                :label="$t('parts.upsert.packageSize')"
              />
              <v-text-field
                v-model.number="part.dangerousGoodsIndicatorProfile"
                :label="$t('parts.upsert.dangerousGoodsIndicatorProfile')"
              />
              <v-text-field
                v-model="part.color"
                type="text"
                prefix="RAL"
                :label="$t('parts.editCard.color')"
              />
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-fade-transition>
      <v-row v-if="isEditMode">
        <v-col cols="12" lg="6">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>
                Leverandører
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="getSupplierDetailHistory">
                <v-icon>
                  mdi-history
                </v-icon>
              </v-btn>
              <PartSupplierUpsertDialog
                :suppliers="model.metadata.suppliers"
                :part-supplier-details="model.data.supplierDetails"
                @add="onAddSupplierDetail($event)"
              />
            </v-toolbar>
            <v-card-text>
              <v-data-table
                :headers="supplierHeaders"
                :items="supplierDetails"
                no-data-text="Ingen leverandører"
                hide-default-footer
              >
                <template #[`item.supplierTitle`]="{ item }">
                  <v-chip v-if="item.usedInListPriceCalculation" color="yellow darken-1" label class="ml-n3">
                    {{ item.supplierTitle }}
                    <v-icon v-if="item.usedInListPriceCalculation" right small>
                      mdi-crown
                    </v-icon>
                  </v-chip>
                  <span v-else>
                    {{ item.supplierTitle }}
                  </span>
                </template>
                <template #[`item.costPrice`]="{ item }">
                  <PartSupplierPrice :price="item.costPrice" :confirmedOn="item.priceConfirmedOn" :stalePriceLimitInDays="item.stalePriceLimitInDays" />
                </template>
                <template #[`item.listPrice`]="{ item }">
                  {{ item.listPrice | formatNumberFixed2 }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <span class="mr-3">
                    <PartSupplierUpsertDialog
                      :item="item"
                      :suppliers="model.metadata.suppliers"
                      :part-supplier-details="model.data.supplierDetails"
                      is-edit-mode
                      @update="simulate"
                    />
                  </span>
                  <v-btn small icon @click="onDeleteSupplierDetails(item)">
                    <v-icon>
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>
                Kunder
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <PartCustomerUpsertDialog
                :customers="model.metadata.customers"
                :part="part"
                :categories="model.metadata.customerPartCategories"
                :category="{ id: part.categoryId, title: getCategoryTitle(part.categoryId) }"
                :disabled="part.customerDetails?.length === model.metadata.customers.length"
                @add="fetchData"
              />
            </v-toolbar>
            <v-card-text>
              <v-data-table
                :headers="customerHeaders"
                :items="model.data.customerDetails"
                no-data-text="Ingen aktive kunder"
                hide-default-footer
              >
                <template #[`item.partCategoryId`]="{ item }">
                  <span v-if="!item.partCategoryId" class="text--secondary">
                    Intet valgt
                  </span>
                  <span v-else>
                    {{ getCustomerCategoryTitle(item.partCategoryId) }}
                  </span>
                </template>
                <template #[`item.title`]="{ item }">
                  <div class="d-flex align-center">
                    <div>{{ item.title }}</div>
                    <v-icon v-if="item.isFavorite" small right color="secondary">
                      mdi-star
                    </v-icon>
                  </div>
                </template>
                <template #[`item.isPreferredByCustomer`]="{ item }">
                  <v-icon
                    v-if="item.isPreferredByCustomer"
                    color="yellow"
                  >
                    mdi-star
                  </v-icon>
                </template>
                <template #[`item.isFavorite`]="{ item }">
                  <v-icon
                    v-if="item.isFavorite"
                    color="yellow"
                  >
                    mdi-star
                  </v-icon>
                </template>
                <template #[`item.actions`]="{ item }">
                  <span class="mr-3">
                    <PartCustomerUpsertDialog
                      :item="item"
                      :part="part"
                      :customers="model.metadata.customers"
                      :categories="model.metadata.customerPartCategories"
                      @update="simulate"
                      is-edit-mode
                    />
                  </span>
                  <v-btn small icon @click="onDeleteCustomerDetails(item)">
                    <v-icon>
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>

    <v-fade-transition>
      <v-row v-if="isEditMode">
        <v-col>
          <v-card>
            <v-row>
              <v-col>
                <v-card-title>
                  Priser
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model.number="part.customListPrice"
                    type="number"
                    prefix="DKK"
                    :label="$t('parts.upsert.customListPrice')"
                    @input="simulate"
                  />
                  <v-text-field
                    v-model.number="customProfit"
                    type="number"
                    suffix="%"
                    :label="$t('parts.upsert.customProfitFactor')"
                    hint="Prioriteres højere end alle andre angivede profitprocenter"
                    @input="simulate"
                  />
                </v-card-text>
              </v-col>
              <v-col>
                <PartPriceOverview
                  :loading="simulating"
                  :list-price="part.listPrice"
                  :part-number="part.partNumber"
                  :price-calculated-on="part.listPriceCalculatedOn"
                  :price-calculation-criteria="part.listPriceCalculationCriteria"
                  :price-fixed-until="part.listPriceFixedUntil"
                  :customer-details="part.customerDetails"
                  :simulation="simulatedModel"
                  :has-changed="hasChanged"
                  :simulation-has-different-price="simulationHasDifferentPrice"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>

    <v-dialog v-model="confirmDeleteCustomerDialog" width="600" persistent>
      <v-card>
        <v-card-title>
          Er du sikker?
        </v-card-title>
        <v-card-text>
          Dette vil fjerne prisen for denne vare til <b>{{ deleteCustomerItem?.customerTitle }}</b>.

          <v-alert v-if="!!deleteCustomerItem?.priceFixedUntil" type="warning" class="mt-5" text>
            Varens pris til denne kunde er låst indtil <b>{{ deleteCustomerItem.priceFixedUntil | formatDateTime }}</b>.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDeleteCustomerDialog = false" text>
            Annuller
          </v-btn>
          <v-btn color="error" @click="deleteCustomerDetail(deleteCustomerItem.id)">
            Ja, fjern kunde
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteSupplierDialog" width="600" persistent>
      <v-card>
        <v-card-title>
          Er du sikker?
        </v-card-title>
        <v-card-text>
          Dette vil fjerne leverandøren <b>{{ deleteSupplierItem?.supplierTitle }}</b>.

          <v-alert v-if="model.data.supplierDetails?.length === 1" type="info" class="mt-5" text>
            Du er ved at fjerne den eneste leverandør til denne vare.
          </v-alert>

          <v-alert v-else-if="deleteSupplierItem?.usedInListPriceCalculation" type="warning" class="mt-5" text>
            Du er ved at slette den foretrukne leverandør.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDeleteSupplierDialog = false" text>
            Annuller
          </v-btn>
          <v-btn color="error" @click="deleteSupplierDetail(deleteSupplierItem)">
            Ja, fjern leverandør
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PartSupplierUpsertDialog from '@/components/part/PartSupplierUpsertDialog.vue'
import PartCustomerUpsertDialog from '@/components/part/PartCustomerUpsertDialog.vue'
import PartPriceOverview from '@/components/part/PartPriceOverview.vue'
import PartSupplierDetailHistory from '@/components/part/PartSupplierDetailHistory.vue'
import ClipboardCopy from '@/components/ClipboardCopy.vue'
import PartSupplierPrice from '@/components/PartSupplierPrice.vue'

export default {
  name: 'PartUpsert',
  components: {
    PartSupplierUpsertDialog,
    PartCustomerUpsertDialog,
    PartPriceOverview,
    PartSupplierDetailHistory,
    ClipboardCopy,
    PartSupplierPrice
  },
  data() {
    return {
      loading: false,
      saving: false,
      creating: false,
      comparePart: {},
      hasChanged: false,
      simulating: false,
      simulateTimeout: null,
      valid: false,
      model: {
        data: {},
        metadata: {}
      },
      simulatedModel: null,
      supplierHeaders: [
        { text: 'Navn', value: 'supplierTitle', sortable: false },
        { text: 'EAN', value: 'ean', sortable: false },
        { text: this.$t('parts.upsert.supplierPrice'), value: 'costPrice', sortable: false, align: 'right' },
        { text: this.$t('parts.upsert.supplierListPrice'), value: 'listPrice', sortable: false, align: 'right' },
        { text: this.$t('parts.upsert.supplierDetailActions'), value: 'actions', sortable: false, align: 'right' }
      ],
      customerHeaders: [
        { text: 'Navn', value: 'customerTitle', sortable: false },
        { text: this.$t('parts.upsert.customerPartCategory'), value: 'partCategoryId', sortable: false },
        { text: this.$t('parts.customerCard.partNumber'), value: 'partNumber', sortable: false },
        { text: this.$t('parts.customerFavorite'), value: 'isPreferredByCustomer', sortable: false, align: 'center' },
        { text: this.$t('parts.tpaFavorite'), value: 'isFavorite', sortable: false, align: 'center' },
        { text: this.$t('parts.upsert.supplierDetailActions'), value: 'actions', sortable: false, align: 'right' }
      ],
      confirmDeleteSupplierDialog: false,
      confirmDeleteCustomerDialog: false,
      deleteCustomerItem: null,
      deleteSupplierItem: null,
      customProfit: null,
      showPSDHistory: false,
      psdHistory: []
    }
  },
  computed: {
    isEditMode() {
      return this.$route.params.id !== undefined;
    },
    part() {
      if (!this.model) return {};

      if (this.model.data.customProfitFactor) {
        this.customProfit = this.model.data.customProfitFactor * 100;
      }

      return this.model.data;
    },
    supplierDetails() {
      return this.part.supplierDetails;
    },
    customerDetails() {
      return this.part.customerDetails;
    },
    simulationHasDifferentPrice() {
      const listPriceChanged = this.part.listPrice !== this.simulatedModel?.listPrice;
      const customerChanges = this.simulatedModel?.customerDetails.some((pcd, index) => {
        const currentPrice = this.customerDetails.find(x => x.customerId === pcd.customerId)?.price;

        if (!currentPrice) return false;

        else return currentPrice !== pcd.price;
      });

      return listPriceChanged || customerChanges;
    },
    filteredCategories() {
      if (this.model.data.groupId) {
        return this.model.metadata.categories.filter((x) => x.groupId === this.model.data.groupId);
      } else {
        return this.model.metadata.categories;
      }
    },
    filteredCustomerPartCategories() {
      if (this.model.data.customerId) {
        return this.model.metadata.customerPartCategories.filter((x) => x.customerId === this.model.data.customerId);
      } else {
        return this.model.metadata.customerPartCategories;
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData: async function() {
      this.loading = true;

      const { id } = this.$route.params;

      if (this.isEditMode && !id) {
        throw 'No ID found when trying to get part';
      }

      try {
        if (this.isEditMode) {
          const { data } = await this.$AuthService.api.get(`adminpart/${id}/edit`);

          this.model = data;
          this.setComparePart(data.data)
          this.simulate();
        } else {
          const { data } = await this.$AuthService.api.get('adminpart/create');
          this.model.metadata = data.metadata;
          this.setComparePart(this.model.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    create: async function() {
      if (!this.$refs.form.validate()) return;

      this.creating = true;

      try {
        const { data } = await this.$AuthService.api.post('/adminpart/create', this.part);

        this.$notifier.showSuccess({ message: 'Varen blev oprettet' });
        await this.$router.replace(`/parts/${data.model.id}/edit`);
        this.fetchData();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Varen blev ikke oprettet. Prøv venligst igen.' });
      } finally {
        this.creating = false;
      }
    },
    save: async function(simulated) {
      const { id } = this.$route.params;

      // Validation

      if (!this.$refs.form?.validate()) return;

      // if (!this.part.customerDetails.length) {
      //   this.$notifier.showMessage({
      //     message: 'Du skal vælge mindst én kunde',
      //     color: 'info'
      //   })

      //   return;
      // }

      if (this.part.customerDetails.some(x => !x.partCategoryId)) {
        this.$notifier.showMessage({
          message: 'Du skal vælge en kundevarekategori',
          color: 'info'
        })

        return;
      }

      /**
       * Handle create, save and simulation scenarios. This is a good candidate for refactoring.
       * TODO: Refactor PartUpsert save
       */

      try {
        this.simulating = simulated;
        this.saving = !simulated;

        const { data } = await this.$AuthService.api.put(`/adminpart/${id}${simulated ? '/simulate' : ''}`, this.part);

        if (simulated) {
          this.simulatedModel = data.model;
        } else {
          this.model.data = data.model;
          this.setComparePart(data.model);

          this.$notifier.showSuccess({ message: 'Varen blev opdateret' });
          this.simulate();
        }
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Varen blev ikke gemt. Prøv igen.' })
      } finally {
        this.saving = false;
        this.simulating = false;
      }
    },
    simulate: async function() {
      clearTimeout(this.simulateTimeout);
      this.simulatedModel = null;

      this.simulateTimeout = setTimeout(() => {
        this.save(true);
      }, 300);
    },
    deletePart: async function() {
      if (confirm(this.$t("parts.upsert.confirmDelete"))) {
        const { id } = this.$route.params;
        
        try {
          this.saving = true;
          const response = await this.$AuthService.api.delete(`/AdminPart/${id}`);

          this.$router.push("/parts");
          this.$notifier.showSuccess({ message: 'Varen blev slettet' });
        } catch(error) {
          console.error(error);
          this.$notifier.showError({ message: 'Varen blev ikke slettet' });
        } finally {
          this.saving = false;
        }
      }
    },
    getSupplierDetailHistory: async function() {
      this.showPSDHistory = true;

      try {
        const response = await this.$AuthService.api.get(`adminpart/${this.$route.params.id}/history`);

        this.psdHistory = response.data;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      }
    },
    deleteSupplierDetail: function(supplierDetail) {
      const index = this.part.supplierDetails.findIndex(x => x.supplierId === supplierDetail.supplierId);
      this.confirmDeleteSupplierDialog = false;
      this.deleteSupplierItem = null;
      
      this.$nextTick(() => {
        this.part.supplierDetails.splice(index, 1);
        this.save();
      })
    },
    deleteCustomerDetail: function(id) {
      const index = this.part.customerDetails?.findIndex(x => x.id === id);
      this.confirmDeleteCustomerDialog = false;
      this.deleteCustomerItem = null;

      this.$nextTick(() => {
        this.part.customerDetails.splice(index, 1);
        this.save();
      })
    },
    isDifferent(object1, object2) {
      const stringedObject1 = JSON.stringify(object1);
      const stringedObject2 = JSON.stringify(object2);
      return stringedObject1 !== stringedObject2;
    },
    setComparePart(part) {
      this.comparePart = JSON.parse(JSON.stringify(part));
      this.hasChanged = false;
    },
    getCategoryTitle: function(id) {
      return this.model.metadata.categories?.find(cat => cat.value === id)?.text;
    },
    getCustomerCategoryTitle: function(id) {
      return this.model.metadata.customerPartCategories?.find(cat => cat.value === id)?.text;
    },
    onDeleteCustomerDetails: function(customerDetail) {
      this.deleteCustomerItem = JSON.parse(JSON.stringify(customerDetail));
      this.confirmDeleteCustomerDialog = true;
    },
    onAddSupplierDetail: function(supplierDetail) {
      this.part.supplierDetails.push(supplierDetail);
      this.simulate();
    },
    onDeleteSupplierDetails: function(supplierDetail) {
      this.deleteSupplierItem = JSON.parse(JSON.stringify(supplierDetail));
      this.confirmDeleteSupplierDialog = true;
    },
    assignCustomerPartCategory: function(value) {
      this.part.customerDetails.map(customer => {
        const { categories, customerPartCategories } = this.model.metadata;
        const customerCategories = customerPartCategories.filter(cat => cat.customerId === customer.customerId);
        const selectedCategory = categories?.find(cat => cat.value === value);

        if (!customerCategories.length) return;

        const customerCategory = customerCategories?.find(x => x.text === selectedCategory.text);

        if (customerCategory) {
          customer.partCategoryId = customerCategory.value;
          // customer.partCategoryTitle = customerCategory.text;
          this.simulate();
        }

      });
    },
    preventNavigation: function(event) {
      console.log('[preventNavigation] isEditMode:', this.isEditMode)
      console.log('[preventNavigation] hasChanged:', this.hasChanged)
      if (this.isEditMode || !this.hasChanged) return;

      event.preventDefault();
      event.returnValue = '';
    }
  },
  watch: {
    customProfit(value) {
      this.model.data.customProfitFactor = value / 100;
    },
    "model.data": {
      handler(newValue) {
        this.hasChanged = this.isDifferent(this.comparePart, newValue);
      },
      deep: true
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNavigation);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNavigation);
  },
  beforeRouteLeave(to, from, next) {
    if (from.path.includes('create')) next();

    else if (this.hasChanged && !this.saving) {
      if (!confirm('Er du sikker på du vil navigere væk? Dine ændringer vil ikke blive gemt.')) {
        return;
      } else {
        next();
      }
    }

    else {
      next();
    }
  }
}
</script>