<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <template #activator="{ attrs, on }">
      <v-icon
        v-if="isEditMode"
        v-bind="attrs"
        v-on="on"
        small
      >
        mdi-pencil
      </v-icon>
      <v-btn
        v-else
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Tilføj leverandør
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="item.supplierId"
                :items="formattedSuppliers"
                :label="$t('parts.upsert.supplier')"
                auto-select-first
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.ean"
                :label="$t('parts.upsert.ean')"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.typeReference"
                :label="$t('parts.upsert.manufacturerPartNumber')"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="item.costPrice"
                type="number"
                :label="$t('parts.upsert.supplierPrice')"
                :rules="rules.costPrice"
                @change="$emit('update')"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="item.listPrice"
                type="number"
                :label="$t('parts.upsert.supplierListPrice')"
                :rules="rules.listPrice"
                @change="$emit('update')"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="item.minimumOrderQuantity"
                type="number"
                :label="$t('parts.upsert.minimumOrderQuantity')"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="item.packageSize"
                type="number"
                :label="$t('parts.upsert.packageSize')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model.number="item.dangerousGoodsIndicatorProfile"
                :label="$t('parts.upsert.dangerousGoodsIndicatorProfile')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="!isEditMode"
          text
          @click="dialog = false"
        >
          Annuller
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="isEditMode" color="primary" @click="onSave">
          Luk
        </v-btn>
        <v-btn v-else color="primary" @click="onSave">
          Tilføj
          <v-icon right>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PartSupplierUpsertDialog',
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          supplierId: '',
          supplierTitle: '',
          ean: '',
          typeReference: '',
          costPrice: null,
          listPrice: null
        }
      }
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    suppliers: {
      type: Array,
      required: true
    },
    partSupplierDetails: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      rules: {
        costPrice: [
          v => (!!v || !!this.item.listPrice) || 'Udfyld enten indkøbspris eller listepris'
        ],
        listPrice: [
          v => (!!v || !!this.item.costPrice) || 'Udfyld enten indkøbspris eller listepris'
        ]
      }
    }
  },
  computed: {
    formattedSuppliers() {
      return this.suppliers.map(supplier => {
        return {
          ...supplier,
          disabled: this.partSupplierDetails.some(psd => psd.supplierId === supplier.value)
        }
      })
    }
  },
  methods: {
    onSave() {
      if (!this.$refs.form.validate()) return;

      if (this.isEditMode) {
        this.$emit('update', this.item)
      } else {
        this.$emit('add', JSON.parse(JSON.stringify(this.item)))
        this.$refs.form.reset();
      }

      this.dialog = false;
    }
  },
  watch: {
    'item.supplierId'(selectedId) {
      const supplier = this.suppliers.find(x => x.value === selectedId);

      this.item.supplierTitle = supplier.text;
    },
  }
}
</script>
