<template>
  <div>
    <v-card
      :loading="loading"
      flat
      :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
    >
      <v-card-title>
        Beregnede priser

        <v-spacer></v-spacer>

        <v-fade-transition>
          <div
            v-if="hasChanged && !simulationHasDifferentPrice"
            class="font-italic text-caption info--text d-flex align-center"
          >
            <v-icon color="info" left> mdi-information-outline </v-icon>
            Dine ændringer har ingen indflydelse på priser
          </div>
        </v-fade-transition>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th class="text-right">
                  Nuværende
                </th>
                <th class="text-right">
                  Efter ændringer
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>TPA (listepris)</td>
                <td></td>
                <td class="text-right">
                  <v-tooltip bottom>
                    <template #activator="{ attrs, on }">
                      <div v-bind="attrs" v-on="on">{{ listPrice | formatNumberFixed2 }}</div>
                    </template>

                    <div>
                      Beregningstidspunkt: {{ priceCalculatedOn | formatDateTime }}
                    </div>
                    <div>
                      Metode: {{ priceCalculationCriteria }}
                    </div>
                    <div v-if="priceFixedUntil">
                      Låst indtil: {{ priceFixedUntil | formatDateTime }}
                    </div>
                  </v-tooltip>
                </td>
                <td class="text-right">
                  <v-tooltip v-if="simulation && isPriceDifferent(listPrice, simulation.listPrice)" bottom>
                    <template #activator="{ attrs, on }">
                      <!-- <div v-bind="attrs" v-on="on">{{ simulation.listPrice | formatNumberFixed2 }}</div> -->
                      <v-chip
                        label
                        v-bind="attrs"
                        v-on="on"
                        :color="simulation.listPrice > listPrice ? 'success' : 'error'"
                      >
                        <v-icon left small>
                          {{ simulation.listPrice > listPrice ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                        </v-icon>
                        {{ simulation.listPrice | formatNumberFixed2 }}
                      </v-chip>
                    </template>

                    <div>
                      Beregningstidspunkt: {{ simulation.listPriceCalculatedOn | formatDateTime }}
                    </div>
                    <div>
                      Metode: {{ simulation.listPriceCalculationCriteria }}
                    </div>
                    <div v-if="priceFixedUntil">
                      Låst indtil: {{ simulation.listPriceFixedUntil | formatDateTime }}
                    </div>
                  </v-tooltip>
                </td>
              </tr>
              <tr v-for="(customerDetail) in customerDetails" :key="customerDetail.id">
                <td>
                  {{ customerDetail.customerTitle }}
                </td>
                <td>
                  <v-btn
                    v-if="customerDetail.recalculationMarkedForPending || getSimulated(customerDetail.customerId)?.recalculationMarkedForPending"
                    small
                    color="info"
                    class="mr-3"
                    :to="'/parts/price-pending?search=' + partNumber"
                  >
                    Godkend ændringer
                    <v-icon right> mdi-arrow-right </v-icon>
                  </v-btn>
                </td>
                <td class="text-right">
                  <v-tooltip bottom>
                    <template #activator="{ attrs, on }">
                      <div v-bind="attrs" v-on="on" class="d-flex justify-end align-center" style="gap: .5rem">
                        <v-icon v-if="customerDetail.priceFixedUntil" small>mdi-lock-outline</v-icon>
                        {{ customerDetail.price | formatNumberFixed2 }}
                      </div>
                    </template>

                    <div>
                      Beregningstidspunkt: {{ customerDetail.priceCalculatedOn | formatDateTime }}
                    </div>
                    <div>
                      Metode: {{ customerDetail.priceCalculationCriteria }}
                    </div>
                    <div v-if="customerDetail.priceFixedUntil">
                      Låst indtil: {{ customerDetail.priceFixedUntil | formatDateTime }}
                    </div>
                  </v-tooltip>
                </td>
                <td width="45%">
                  <div class="d-flex align-center justify-end">
                    <v-tooltip v-if="simulation && isPriceDifferent(getSimulated(customerDetail.customerId).price, customerDetail.price)" bottom>
                      <template #activator="{ attrs, on }">
                        <v-chip
                          label
                          v-bind="attrs"
                          v-on="on"
                          :color="getSimulated(customerDetail.customerId).price > customerDetail.price ? 'success' : 'error'"
                        >
                          <v-icon left small>
                            {{ getSimulated(customerDetail.customerId).price > customerDetail.price ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                          </v-icon>
                          {{ getSimulated(customerDetail.customerId).price | formatNumberFixed2 }}
                        </v-chip>
                      </template>
                      <div>
                        Beregningstidspunkt: {{ getSimulated(customerDetail.customerId).priceCalculatedOn | formatDateTime }}
                      </div>
                      <div>
                        Metode: {{ getSimulated(customerDetail.customerId).priceCalculationCriteria }}
                      </div>
                      <div v-if="getSimulated(customerDetail.customerId).priceFixedUntil">
                        Låst indtil: {{ getSimulated(customerDetail.customerId).priceFixedUntil | formatDateTime }}
                      </div>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-fade-transition>
        <v-card-text v-if="hasChanged">
          <div class="text-caption">
            Prisændringer træder først ikraft når varen opdateres.
          </div>
        </v-card-text>
      </v-fade-transition>
    </v-card>
  </div>
</template>

<script>
import PartCustomerPriceDetails from '@/components/part/PartCustomerPriceDetails.vue'

export default {
  name: 'PartPriceOverview',
  components: {
    PartCustomerPriceDetails
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    listPrice: {
      type: Number | undefined,
      required: true
    },
    partNumber: {
      type: String,
      required: true
    },
    priceCalculatedOn: {
      type: String,
      default: null
    },
    priceCalculationCriteria: {
      type: String,
      default: null
    },
    priceFixedUntil: {
      type: String,
      default: null
    },
    newListPrice: {
      type: Number,
      default: null
    },
    customerDetails: {
      type: Array,
      required: true
    },
    simulation: {
      type: Object,
      default: () => ({})
    },
    hasChanged: {
      type: Boolean,
      required: true
    },
    simulationHasDifferentPrice: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    getSimulated(customerId) {
      return this.simulation?.customerDetails.find(x => x.customerId === customerId);
    },
    isPriceDifferent(a, b) {
      return Number(a).toFixed(2) != Number(b).toFixed(2)
    }
  }
}
</script>