<template>
  <div>
    <v-row>
      <v-col>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Prishistorik
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close')">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row class="px-5">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="records"
          no-data-text="Ingen data tilgængelig"
          hide-default-footer
          :items-per-page="-1"
        >
          <template #[`item.validFrom`]="{ item }">
            {{ item.validFrom | formatDateTime }}
          </template>
          <template #[`item.validTo`]="{ item }">
            {{ item.validTo | formatDateTime }}
          </template>
          <template #[`item.costPrice`]="{ item }">
            {{ item.costPrice | formatNumberFixed2 }}
          </template>
          <template #[`item.listPrice`]="{ item }">
            {{ item.listPrice | formatNumberFixed2 }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PartSupplierDetailHistory',
  props: {
    records: {
      type: Array,
      required: true
    }
  },
  computed: {
    costPrices() {
      return this.records.map(x => x.costPrice);
    },
    listPrices() {
      return this.records.map(x => x.listPrice);
    },
  },
  data() {
    return {
      headers: [
        { text: 'Leverandør', value: 'supplier.title', sortable: false },
        { text: 'Gyldig fra', value: 'validFrom', sortable: false },
        { text: 'Gyldig til', value: 'validTo', sortable: false },
        { text: 'Kostpris', value: 'costPrice', align: 'end', sortable: false },
        { text: 'Listepris', value: 'listPrice', align: 'end', sortable: false }
      ]
    }
  }
}
</script>
