<template>
  <v-dialog v-model="dialog" max-width="800">
    <template #activator="{ attrs, on }">
      <v-icon
        v-if="isEditMode"
        v-bind="attrs"
        v-on="on"
        small
      >
        mdi-pencil
      </v-icon>
      <v-btn
        v-else
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ isEditMode ? 'Rediger' : 'Tilføj' }} kunde
        <v-spacer></v-spacer>
        <v-checkbox v-model="item.isFavorite" label="Marker som favorit" />
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="item.customerId"
                :items="formattedCustomers"
                :label="$t('parts.upsert.customer')"
                :disabled="isEditMode"
                auto-select-first
                :rules="[ v => !!v || 'Skal udfyldes' ]"
                @input="assignCustomerPartCategory($event)"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="item.partCategoryId"
                :items="filteredCategories"
                item-text="text"
                item-value="value"
                :label="$t('parts.upsert.customerPartCategory')"
                auto-select-first
                :rules="[ v => !!v || 'Skal udfyldes' ]"
                @change="$emit('update')"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.number="item.customListPrice"
                type="number"
                label="Fast kundepris"
                prefix="DKK"
                @input="$emit('update')"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.number="profitFactor"
                label="Fast profit"
                suffix="%"
                @input="$emit('update')"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.number="listPriceDiscount"
                label="Listepris rabat"
                suffix="%"
                @input="$emit('update')"
              />
            </v-col>
            <v-col cols="4">
              <v-datetime-picker
                v-model="item.priceFixedUntil"
                useIso
                label="Pris låst indtil"
                :time-picker-props="{ format: '12hr' }"
                :date-picker-props="{ firstDayOfWeek: 1 }"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    @click.native="parent.clearHandler"
                  >
                    Fjern
                  </v-btn>
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    Annuller
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    OK
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.number="item.expectedSalePerYear"
                label="Forventet salg pr. år"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="item.customerPartTitle"
                label="Vare titel"
                hint="Erstatter TPA vare titel"
                persistent-hint
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.customerDescription"
                label="Kundebeskrivelse"
                auto-grow
                hint="Synlig for kunden"
                persistent-hint
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.tpaDescription"
                label="TPA beskrivelse"
                auto-grow
                outlined
              />
            </v-col>
            <v-col>
              <v-textarea
                v-model="item.note"
                label="Note"
                auto-grow
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">
          Annuller
        </v-btn>
        <v-btn v-if="isEditMode" color="primary" @click="onSave">
          Gem
          <v-icon right>
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn v-else color="primary" @click="onCreate">
          Tilføj
          <v-icon right>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PartCustomerUpsertDialog',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
        return {
          customerId: '',
          partCategoryId: '',
          price: null,
          isFavorite: true
        }
      }
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    customers: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    category: {
      type: Object,
      default: null
    },
    part: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      syncing: false,
      creating: false,
      valid: false
    }
  },
  computed: {
    profitFactor: {
      get() {
        if (this.item.customProfitFactor) {
          return Number(this.item.customProfitFactor * 100).toFixed(0);
        } else {
          return null;
        }
      },
      set(newValue) {
        this.item.customProfitFactor = !!newValue ? newValue / 100 : null;
      }
    },
    listPriceDiscount: {
      get() {
        if (this.item.customListPriceDiscount) {
          return Number(this.item.customListPriceDiscount * 100).toFixed(0);
        } else {
          return null;
        }
      },
      set(newValue) {
        this.item.customListPriceDiscount = !!newValue ? newValue / 100 : null;
      }
    },
    filteredCategories() {
      return this.categories.filter(x => x.customerId === this.item.customerId)
    },
    formattedCustomers() {
      return this.customers.map(customer => {
        return {
          ...customer,
          disabled: this.part.customerDetails.some(customerDetail => customerDetail.customerId === customer.value)
        }
      })
    }
  },
  methods: {
    onSave() {
      if (!this.$refs.form.validate()) return

      this.$emit('update', this.item);
      this.dialog = false;
    },
    async onCreate() {
      if (!this.$refs.form.validate()) return

      try {
        this.creating = true

        await this.$AuthService.api.post(`adminpart/${this.$route.params.id}/add-customer-detail`, this.item)
        this.dialog = false
        this.$refs.form.reset()
        this.$emit('add')
      } catch (error) {
        console.error(error)
      } finally {
        this.creating = false
      }
    },
    assignCustomerPartCategory(value) {
      const customerCategories = this.categories.filter(cat => cat.customerId === value);

      if (!customerCategories.length) return;

      const customerCategory = customerCategories.find(x => x.text === this.category.title);

      this.item.partCategoryId = customerCategory.value;
    },

    setNow() {
      this.item.priceFixedUntil = this.moment().format('YYYY-MM-DDTHH:mm')
    },

    // async syncToERP() {
    //   this.syncing = true;
    //   await this.$store.dispatch('syncToERP', this.item.id);
    //   this.syncing = false;
    // }
  },
  watch: {
    'item.customerId'(selectedId) {
      const customer = this.customers.find(x => x.value === selectedId);

      this.item.customerTitle = customer.text;
    }
  }
}
</script>
